import React, { useCallback, useEffect, useState } from "react";
import Menu from "../menu";
import { useDispatch, useSelector } from "react-redux";
import Search from "../common/Search";
import Dropdown from "../common/Dropdown";
import CustomDropdown from "../common/customDropdown";
import API from "../../redux/api/api";
import { getOutlets } from "../../redux/actions/employeeActions";
import { clearMenuData } from "../../redux/actions/menuAction";
import MerchantLogo from "../../assets/images/thalappakatti.png";
import user from "../../assets/images/user_one.png";
import { ReactComponent as Stats } from "../../assets/svg/statistics.svg";
import logout from "../../assets/images/logout.png";
import { signOut } from "../../redux/actions/authActions";
import { useHistory, useLocation } from "react-router";
import { IS_SPORT_VERTICAL, STORAGE_BUCKET_URL, IS_SPORT_DOMAIN } from "../../shared/constants";
import { selectBranch } from "../../redux/actions/authActions";
const axios = require("axios");

const reportCategory = [
  { id: 32, option: "Sales" },
  // { id: 3, option: "Delivery" },
  // { id: 4, option: "Pick Up" },
];

const Report = (props) => {
  const credentials = useSelector((state) => state.auth.credentials);
  const outlets = useSelector((state) => state.employee.outlets);
  const restaurantDetails = useSelector(
    (state) => state.auth.restaurantDetails
  );
  const merchantId = credentials?.merchantId;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [iframeSource, setiFrameSource] = useState("");
  const [reportId, setReportId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [branchName, setBranchName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectValue, setSelectValue] = useState(
    location.state ? location.state : reportCategory[0].option
  );
  const [reportData, setReportData] = useState([]);
  const [singleBranchId, setSingleBranchId] = useState(
    restaurantDetails?.branch?.length > 0 && restaurantDetails?.branch[0].id
  );
  const branchDetails = useSelector((state) => state.auth.selectedBranch);
  useEffect(() => {
    getReportData(branchDetails.id);
  }, [branchDetails]);

  useEffect(() => {
    if (credentials) {
      dispatch(getOutlets(credentials?.merchantId));
    }
    //console.log(props.id, 'id');
  }, []);

  const handleSelect = (event) => {
    setSelectValue(event.target.value);
    if (event.target.value === 1) {
      history.push("/management/report/32", "Check In");
    } else if (event.target.value === 32) {
      history.push("/management/report/32", "Sales");
    }
  };

  useEffect(() => {
    if (outlets.length == 0 && credentials) {
      setBranchId(credentials?.locationId);
    }
  }, [outlets]);

  useEffect(() => {
    if (credentials) {
      dispatch(getOutlets(credentials?.merchantId));
    }
  }, [credentials]);

  const [headerDetails, setHeaderDetails] = useState({
    merchantName: "Thalapakatti Biriyani",
    merchantAddress: "Aarapalayam",
    merchantLogo: MerchantLogo,
    UserProfileImage: user,
  });
  // console.log(`props`, props);
  async function fetchData() {
    const token = credentials?.accessToken;
    API({
      method: "get",
      url: "/merchants/" + branchId + "/reports/" + reportId + "/",
      headers: {
        Authorization: "bearer " + token,
      },
    })
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          //console.log(res.data.url);
          setiFrameSource(res.data.url);
        } else {
          setError("please try again later");
        }
      })
      .catch((err) => {
        //console.log(err);
        setError("please try again later");
      });
  }

  useEffect(() => {
    if (reportId !== "") {
      fetchData();
    }
  }, [reportId, branchId]);

  async function fetchData() {
    setLoading(true);
    const token = credentials?.accessToken;
    API({
      method: "get",
      url:
        "/merchants/" +
        merchantId +
        "/location/" +
        branchId +
        "/reports/" +
        reportId,
      headers: {
        Authorization: "bearer " + token,
      },
    })
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          //console.log(res.data.url);
          setiFrameSource(res.data.url);
          setLoading(false);
        } else {
          setError("please try again later");
        }
      })
      .catch((err) => {
        //console.log(err);
        setError("please try again later");
      });
  }

  const getReportData = async (locationId) => {
    const token = credentials?.accessToken;
    let reportId =
      restaurantDetails.country == "US" &&
      location.pathname === "/management/report/32"
        ? 41
        : restaurantDetails.country == "IN" &&
          location.pathname === "/management/report/32"
        ? 32
        : location.pathname === "/management/report/51"
        ? 51
        : location.pathname === "/management/report/57"
        ? 57
        : location.pathname === "/management/report/63"
        ? 63
        : location.pathname === "/management/report/67"
        ? 67
        : location.pathname === "/management/report/82"
        ? 82
        : 2;
    API({
      method: "get",
      url:
        "/merchants/" +
        merchantId +
        "/location/" +
        locationId +
        "/reports/" +
        reportId,
      headers: {
        Authorization: "bearer " + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setReportData(res.data?.url);
          setiFrameSource(res.data.url);
        } else {
          setError("please try again later");
        }
      })
      .catch((err) => {
        setError("please try again later");
      });
  };

  const logoutUser = () => {
    dispatch(clearMenuData());
    localStorage.clear();
    dispatch(signOut());
    history.replace("/");
  };

  const getImageURL = useCallback(
    (type) => {
      if (
        restaurantDetails &&
        restaurantDetails.media &&
        restaurantDetails.media.length > 0
      ) {
        const logoMedia = restaurantDetails.media.filter(
          (media) => media.entityType == type
        )[0];

        return (
          STORAGE_BUCKET_URL +
          logoMedia.mimeType.split("/")[0] +
          "/" +
          logoMedia.id +
          "." +
          logoMedia.mimeType.split("/")[1]
        );
      } else {
        return "";
      }
    },
    [restaurantDetails]
  );

  return (
    <div className="menu-items">
      <div className="header">
        <div className="logo-container">
          <div>
            <img src={getImageURL("LOGO")} className="restaurant-logo" />
          </div>
          <div className="restaurant-name-container">
            <span className="restaurant-name">
              {restaurantDetails &&
                restaurantDetails.branchName &&
                restaurantDetails.branchName.split(",")[0]}
            </span>
            <div>
              <select
                className="branch-dropdown"
                onChange={(e) => {
                  setSingleBranchId(JSON.parse(e.target.value)?.id);
                  dispatch(selectBranch(JSON.parse(e.target.value)));
                }}
              >
                {restaurantDetails &&
                  restaurantDetails.branch &&
                  restaurantDetails.branch.map((u, i) => {
                    return (
                      <option value={`${JSON.stringify(u)}`}>
                        {u.locationName.split(",")[1]}
                      </option>
                    );
                  })}
                {/* <option value="Madurai">Madurai </option>
                <option value="K. K. Nagar">K. K. Nagar</option> */}
              </select>
            </div>
          </div>
        </div>
        {/* <img src={headerDetails.merchantLogo} />
          <div>
            <p>{headerDetails.merchantName}</p>
            <p>{headerDetails.merchantAddress}</p>
          </div>
          <img
            src={headerDetails.UserProfileImage}
            className="user-profile"
            alt="loading" /> */}
        <p
          className="logout-user"
          onClick={logoutUser}
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
        >
          <img src={logout} alt="Logout" height="20" />
          &nbsp; Log Out
        </p>
      </div>
      <div
        className="report-checkin-dropDown"
        style={{ width: "100%", marginTop: "30px" }}
      >
        {/* <Dropdown
          color={"#979797"}
          data={reportCategory}
          selectValue={selectValue}
          handleSelect={handleSelect}
        /> */}
      </div>

      <div
        className="header-menu"
        style={{
          justifyContent: "space-between",
        }}
      >
        {/* <div>
          <Stats className="menu-items-SVG" />
          <h2
            style={{
              fontSize: "1.1vw",
            }}
          >{`Reports & Insights  >   ${props.title}`}</h2>
        </div> */}
        <div>
          {selectValue === "Sales" && (
            <div
              className={`${
                location.pathname === "/management/report/32"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/32", "Sales")}
            >
              Sales Report
            </div>
          )}
           {selectValue === "Sales" && (
            <div
              className={`tab ${
                location.pathname === "/management/report/82"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/82", "Sales")}
            >
              Category Report
            </div>
          )}
          {/* {
            <div
              className={` ${
                location.pathname === "/management/report/32"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/32", "Check In")}
            >
              Checkin Report
            </div>
          } */}

              {
              restaurantDetails.vertical== IS_SPORT_DOMAIN  
              && (
                <div
                  className={`tab ${
                    location.pathname === "/management/report/67"
                      ? "selected"
                      : "unselected"
                  }`}
                  onClick={() => history.push("/management/report/67", "Sales")}
                >
                Enrolment tracker
                </div>
              )

          } 
          {selectValue === "Sales" && (
            <div
              className={` ${
                location.pathname === "/management/report/57"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/57", "Sales")}
            >
              Product Insights
            </div>
          )}

          {
            <div
              className={`tab ${
                location.pathname === "/management/report/2"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/2", "Sales")}
            >
              Check-In Report
            </div>
          }

          {selectValue === "Sales" && (
            <div
              className={` ${
                location.pathname === "/management/report/51"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/51", "Sales")}
            >
              Customer Insights
            </div>
          )}
           {branchDetails.cusine!=null && branchDetails.cusine[0]!=null &&
            branchDetails.cusine[0]== IS_SPORT_VERTICAL && selectValue === "Sales"  && (
            <div
              className={` ${
                location.pathname === "/management/report/63"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/63", "Sales")}
            >
            Consolidated Report
            </div>
          )}
          {/* {selectValue === "Sales" && (
            <div
              className={`tab ${
                location.pathname === "/management/report/4"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/4", "Sales")}
            >
              Order insights
            </div>
          )} */}
          {/* {selectValue === "Sales" && (
            <div
              className={`tab ${
                location.pathname === "/management/report/12"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => history.push("/management/report/12", "Sales")}
            >
              Sales insights
            </div>
          )} */}
          {/* <div
            className={`tab ${
              location.pathname === "/management/report/4"
                ? "selected"
                : "unselected"
            }`}
            style={{
              borderBottom:
                location.pathname === "/management/report/4"
                  ? "3px solid #67833E"
                  : "3px solid #fff",
              color:
                location.pathname === "/management/report/4"
                  ? "#67833E"
                  : "rgba(0, 0, 0, 0.5)",
            }}
            onClick={() => history.push("/management/report/4")}
          >
            Weekly Report
          </div> */}
        </div>
        {/* <CustomDropdown
          options={Array.from(
            outlets,
            (outlet) => outlet.locationName.split(",")[1]
          )}
          placeholder={"Select Branch"}
          onSelect={(outletSelected) => {
            const outletObject = outlets.filter((outlet) =>
              outlet.locationName.includes(outletSelected.value)
            );
            setBranchId(outletObject[0].id);
            setBranchName(outletSelected.value);
            setReportId(props.id);
          }}
          value={branchName}
          name={"Branch"}
          controlClassName={"report-dropdown"}
          arrowClassName={"report-dropdown-arrow"}
        /> */}
      </div>
      {reportData ? (
        <iframe
          className="reportData-deskTop"
          src={reportData}
          frameBorder="0"
          width="1000"
          height="5000"
          allowtransparency="true"
          scrolling="no"
        ></iframe>
      ) : loading ? (
        <p
          className="menu-list"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "25%",
          }}
        >
          Loading, Please Wait!!!
        </p>
      ) : error !== "" ? (
        <p
          className="menu-list"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "25%",
          }}
        >
          {error}
        </p>
      ) : null}
      {reportData && window.innerWidth <= 575 ? (
        <iframe
          className="reportData-mobile"
          src={reportData}
          frameBorder="0"
          width="1000"
          height="5000"
          allowtransparency="true"
          scrolling="no"
        ></iframe>
      ) : loading ? (
        <p
          className="menu-list"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "25%",
          }}
        >
          Loading, Please Wait!!!
        </p>
      ) : error !== "" ? (
        <p
          className="menu-list"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "25%",
          }}
        >
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default Report;
